:root {
    --color-dark-teal: rgb(2, 62, 71);
    --color-light-teal: rgb(9, 120, 137);
    --color-brown: rgb(122, 94, 47);
    --color-background: #1b1b1b;
    --color-background-light: rgb(242, 240, 234);

    --button-background-color: #86362A;
    --button-font-color: #FFFFFF;
    --button-background-color-hover: #52221b;
    --button-font-color-hover: #FFFFFF;
    --button-border-radius: 20px;
  }