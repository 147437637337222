h2.title.is-2 {
    margin-left: 20px;
    margin-bottom: 10px;
}

.inner-page-container {
    margin-top: 30px;
    padding-bottom: 20px;
}

ul.recipe-list li a {
    font-size: 16px;
}

.hero {
    display: flex;
    flex-direction: column;
}

.hero img {
    width: 100%;
    height: auto;
    display: block;
}

.recipe-search {
    margin: 2rem 0;
}

.search-wrapper {
    position: relative;
    width: 50%;
    margin-left: 15px;
}

.search-wrapper input {
    width: 100%;
    padding: 12px 40px 12px 16px;
    border: 1px solid #e0e0e0;
    border-radius: 24px;
    font-size: 1rem;
    transition: border-color 0.2s ease;
    outline: none;
}

.search-wrapper input:focus {
    border-color: #B89B7D;  /* Your site's brown color */
}

.search-wrapper input::placeholder {
    color: #999;
}

.search-icon {
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
    pointer-events: none;
}

ul.recipe-list {
    margin-left: 20px;
}

ul.recipe-list li {
    list-style: none;
    list-style-type: none;
}


.pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid #eee;
}

.pagination button {
    background-color: var(--color-brown);
    color: white;
    padding: 8px 20px;
    border: none;
    border-radius: 20px;
    font-size: 0.9rem;
    transition: all 0.2s ease;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;  /* Space between icon and text */
}

.pagination-icon {
    stroke-width: 1.5;  /* Makes the icons a bit thinner */
}

.pagination button:hover:not(:disabled) {
    background-color: #a88b6d;  /* Slightly darker shade of your brown */
}

.pagination button:disabled {
    background-color: #e0e0e0;
    color: #999;
    cursor: not-allowed;
}