#right-column h3 {
    margin-bottom: 10px;
}

#right-column .button {
    margin-top: 20px;
}

#right-column.sidebar {
    color: var(--color-dark-teal);
}

#right-column.sidebar h3 {
    font-weight: 500;
}
