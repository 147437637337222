
  
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* If you need to adjust the texture opacity, you could use a semi-transparent background color */
    /* background-color: rgba(242, 240, 234, 0.95); */
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
  
  .button.is-link {
    background-color: var(--button-background-color);
    color: var(--button-font-color);
    border-radius: var(--button-border-radius);
    padding: 0.5rem 1rem;
    height: 2.0em;
  }
  
  .button.is-link:hover {
    background-color: var(--button-background-color-hover);
    color: var(--button-font-color-hover);
  }

  a {
    text-decoration: none;
    color: var(--color-brown);
    font-size: 0.90rem;
  }
  
  h2 {
    font-size: 25.92px;
    color: var(--color-dark-teal);
    font-weight: 400;
  }
  
  h3 {
    font-size: 14.92px;
    color: var(--color-dark-teal);
    font-weight: 200;
    letter-spacing: 2.0px;
    text-transform: uppercase;
    line-height: 25.9167px;
  }
  
  ul, ol {
    margin-left: 40px;
  }

  /* Import Noto Sans from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;700&display=swap');

/* Apply it globally */
:root {
  --custom-font: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 
    sans-serif;
}

/* Apply to everything */
* {
  font-family: var(--custom-font);
}

/* Specifically target Bulma classes to ensure override */
.title,
.subtitle,
.content,
.button,
.input,
.textarea,
.select select,
.label,
.menu-label,
.navbar-item,
.navbar-link {
  font-family: var(--custom-font) !important;
}


h2.title.is-2 {
  font-size: 25px;
}

.inner-page-container nav.jc-breadcrumb {
  display: block;
  justify-content: initial;
  padding: 0;
}

