#site-wrapper {
    min-height: 100vh;
    background-color: rgba(26, 26, 26, 0.8);
    background-image: url('../assets/textures/vertical-planks-small.webp');
    background-blend-mode: overlay;
    background-repeat: repeat;
    padding-top: 50px;
}



#root {
    padding-bottom: 40px;
}

#page-section {
    padding-top: 1rem;
    background-color: var(--color-background);
    background-image: url('../assets/textures/parchment.jpg');
    background-repeat: repeat;
    max-width: 1200px;
    margin: 0px auto;
    padding: 0px;
    box-shadow: 0px 0px 46px 6px rgba(0,0,0,0.65);
}

.inner-page-container {
    padding-left: 40px;
    padding-right: 40px;
}

nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 30px;
}

nav a {
    margin: 0 15px;
    color: var(--color-dark-teal);
    text-decoration: none;
    padding: 10px 16px;
    font-size: 16px;
    position: relative;
    transition: color 0.2s ease;
}

nav a:hover {
    color: var(--color-light-teal);
}

/* Base style for the underline */
nav a::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 50%;
    background-color: var(--color-light-teal);
    transition: all 0.3s ease;
}

/* Hover underline animation */
nav a:hover::after {
    width: 100%;
    left: 0;
}

/* Click effect */
nav a:active {
    transform: translateY(1px);
}

/* Active link state */
nav a.active {
    color: var(--color-light-teal);
}

/* Active link underline */
nav a.active::after {
    width: 100%;
    left: 0;
}



footer {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 40px;
    background-image: url('../assets/textures/footer-background.jpg');
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#title {
    padding: 20px 20px 20px 40px;
    margin: 0;
}

#title h1 {
    font-size: 22px;
    color: var(--color-dark-teal);
    font-weight: 200;
    letter-spacing: 9.96px;
    text-transform: uppercase;
    line-height: 29.9167px;
}

#title-columns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 13px;
    margin-bottom: 10px;
}

#title-columns .column {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

#title-columns .column::before,
#title-columns .column::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid black;
    margin: 0 20px;
}

#title-columns .column:first-child::before,
#title-columns .column:last-child::after {
    display: none;
}


@media screen and (max-width: 1200px) {
    #site-wrapper {
        padding-top: 0px;
    }
}

@media screen and (max-width: 768px) {

    header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    #title {
      padding-bottom: 10px;
    }

    nav {
      width: 100%;
      padding-right: 0;
      margin-top: 0px;
      justify-content: flex-start;
      padding-left: 40px;
      padding-bottom: 20px;
    }

    nav a {
       margin-left: -10px;
       padding-bottom: 5px;
       padding-top: 5px;
       font-size: 14px;
    }
  }