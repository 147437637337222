.recipe-list {
    margin-top: 15px;
}

ul li {
    list-style-type: disc;
}

ul li a {
    color: var(--color-brown);
    font-size: 18px;
    line-height: 28px;
    text-underline-offset: 0.9px;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.button.is-primary {
    background-color: var(--color-light-teal);
    font-size: 12.5px;
    text-transform: uppercase;
    border-radius: 3px;
    padding: 20px 20px;
    font-weight: 700;
    color: var(--color-background-light);
}

.button.is-primary:hover {
    background-color: var(--color-light-teal);
    color: var(--color-background-light);
}

.pagination {
    border-top: 1px solid var(--color-dark-teal);
    margin-top: 20px;
    padding-top: 5px;
}

.back {
    display: block;
    padding-bottom: 30px;
    color: var(--color-brown);
}

.recipe-details {
    font-size: 18px;
    color: var(--color-dark-teal);
}

.recipe-details .introduction {
    margin-top: 20px;
}

.recipe-details h3 {
    margin-top: 20px;
}

.recipe-details .publication-date {
    color: var(--color-light-teal);
    font-size: 15.008px;
}

footer a {
    color: #ffffffd7;
    font-size: 15.008px;
    text-transform: uppercase;
    padding: 0 40px 40px 0px;
    font-size: 12px;
    text-decoration: underline;
    text-underline-offset: 12px;
}

footer a:hover {
    color: #fff;
}
