.inner-page-container {
    margin-top: 30px;
    padding-bottom: 60px;
}

.recipe-details {
    font-size: 14px;
}

.recipe-details h2 {
    font-size: 1.5rem;  /* Start with desired size */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
    min-inline-size: 0;
    text-wrap: nowrap;
    
    /* Only scale down if width is below 600px */
    @media (max-width: 600px) {
        font-size: clamp(0.25rem, 5vw, 1.5rem);
    }
}

.hero {
    display: flex;
    flex-direction: column;
}

.hero img {
    width: 100%;
    height: auto;
    display: block;
}