/* styles/components/sidebar.css */

.sidebar-content {
    margin-top: 1rem;
}

.sidebar-heading {
    color: #b89b7d;  /* Matches your site's brown color */
    font-size: 14px;
    font-weight: normal;
    border-bottom: 3px solid #b89c7da6;
    padding-bottom: 0.5rem;
    margin-bottom: 1.5rem;
}

.heart {
    display: inline-block;
    vertical-align: middle;
    margin-left: 4px;
    margin-top: -2px; /* Fine-tune vertical alignment */
    transform: rotate(10deg);
}

.chef-picks-item {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #b89c7da6;
}

.chef-picks-item:last-child {
    border-bottom: none;
}

.chef-picks-item a {
    color: inherit;
    text-decoration: none;
}

.chef-picks-item img {
    width: 100%;
    height: auto;
    margin-bottom: 0.75rem;
    border-radius: 4px;
}

.product-info {
    padding: 0 0.5rem;
}

.product-type {
    font-size: 1rem;
    color: #333;
    margin-bottom: 0.5rem;
    font-weight: 500;
}

.product-desc {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 0.5rem;
    line-height: 1.4;
}

.product-details {
    font-size: 0.8rem;
    color: #888;
    line-height: 1.4;
}