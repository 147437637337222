
#homepage .content {
    margin-top: 15px;
}

#homepage .recipe-details {
    margin-bottom: 50px;
}

#homepage .recipe-details a {
    font-size: 18px;
}

.homepage-hero {
    display: flex;
    flex-direction: column;
}

.homepage-hero img {
    width: 100%;
    height: auto;
    display: block;
}

.homepage-hero header {
    order: -1; /* This moves the header before the images */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}


#homepage article p {
    font-size: 14px;
    color: #363033;
}

#homepage article p.posting-date {
    font-size: 14px;
    color: #A3865E;
    margin-bottom: 0px;
    text-transform: uppercase;
}

#homepage article h2 {
    margin-top: 10px;
    font-weight: bold;
}

#homepage article.news-article {
    margin-bottom: 25px;
    padding-bottom: 45px;
    border-bottom: 2px solid #a3865e67;
}

#homepage article.news-article .meta {
    margin-top: 10px;
}