.page-layout {
    width: 100%;
  }
  
  .hero-container {
    width: 100%;
    display: block;
    line-height: 0; /* This removes any extra space from line-height */
  }
  
  .hero-container img {
    width: 100%;
    display: block; /* This removes any default inline image spacing */
  }
  
  .ornamental-strip-container {
    width: 100%;
    display: block;
    line-height: 0;
  }
  
  .ornamental-strip-container img {
    width: 100%;
    display: block;
    opacity: 0.85;
    box-shadow: 0px -10px 15px -7px rgba(0,0,0,0.75);    
  }

  