/* Reset any inherited nav styles for breadcrumbs */
.inner-page-container nav.jc-breadcrumb {
    display: block;
    justify-content: initial;
    padding: 0;
}

/* Main breadcrumb container */
.jc-breadcrumb {
    margin: 1.5rem 0 1.5rem 0;
    text-align: left;
}

/* Breadcrumb list */
.jc-breadcrumb ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
}

/* Breadcrumb items */
.jc-breadcrumb li {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    color: #666;
}

/* Override generic list item link styles */
.jc-breadcrumb ul li a {
    color: #666;
    font-size: 0.9rem;
    line-height: normal;
    text-underline-offset: 0;
    white-space: normal;
    overflow: visible;
    text-overflow: clip;
    display: inline;
}

/* Override nav link styles */
.jc-breadcrumb nav a,
.jc-breadcrumb a {
    margin: 0;
    padding: 0;
    font-size: 0.9rem;
    position: static;
    transition: none;
    color: #666;
    text-decoration: none;
}

/* Separator between items */
.jc-breadcrumb li:not(:last-child):after {
    content: "//";
    margin: 0 0.5em;
    color: #ccc;
    font-size: 0.9rem;
}

/* Hover state for links */
.jc-breadcrumb a:hover {
    color: #333;
}

/* Current page indicator */
.jc-breadcrumb li.current {
    color: #666;
    font-size: 0.9rem;
}

/* Reset any nav link animations for breadcrumb links */
.jc-breadcrumb a::after {
    display: none;
}